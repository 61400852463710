
  import info from "@/components/_universal/list-info.vue";
  import tablelist from "@/components/_universal/list-table.vue";

  // Standard Components
  import EVENTS from "@/constants/events";

  // Controller
  import EmployeeController from "@/controllers/employee-controller";
  import listMixin from "@/mixin/list-mixin";

  // Initialize the EmployeeController instance (reuse the same instance)
  const employeeController = new EmployeeController();

  export default {
    mixins: [listMixin],
    data() {
      return {
        listDataObj: {},
        EVENT_ACTION: {},
        project: {},
        timer: null,         // Holds the interval timer ID.
        apiCallCount: 0,     // Tracks the total number of API calls.
        unsubscribe: null,   // Holds the Vuex subscription function.
      };
    },
    methods: {
      async onFilterChange() {
        // Get the current filter from the store (if available)
        if (this.$store.getters.getList.filter) {
          this.filter = this.$store.getters.getList.filter;
        }
        // Call the API using the controller.
        let data = await employeeController.getListResults(this.filter);
        // Update the Vuex store with the API results.
        await this.updateResults(data.resources);
      },
      async loadData() {
        // Load initial list data via the controller.
        this.listDataObj = await employeeController.list();
        // If data retrieval was successful, update the store.
        if (this.listDataObj.table.data.success) {
          this.updateResults(this.listDataObj.table.data.resources);
        }
      },
      async updateResults(results) {
        // Dispatch new list results to the Vuex store.
        this.$store.dispatch("setList", results);
      },
      subscribeOnFilterChange() {
        // Subscribe to filter changes in the Vuex store.
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type === "setFilter") {
            this.onFilterChange();
          }
        });
      },
    },
    components: {
      info,
      tablelist,
    },
    async created() {
      // Import event constants.
      this.EVENT_ACTION = EVENTS;
      // Load initial data.
      await this.loadData();
    },
    async mounted() {
      this.subscribeOnFilterChange();

      // Set up a timer to call onFilterChange() every 20 seconds.
      this.timer = setInterval(async () => {
        // If 30 API calls have been made, clear the timer.
        if (this.apiCallCount >= 30) {
          clearInterval(this.timer);
          this.timer = null;
        
          return;
        }
        await this.onFilterChange();
        this.apiCallCount++;
       
      }, 20000); // 20,000 ms = 20 seconds
    },
    beforeDestroy() {
      // Clean up: reset store data.
      this.updateResults(null);
      // Unsubscribe from Vuex store events if subscription exists.
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      // Clear the interval timer if it exists.
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  };
